<template>
  <div class="send_certificates">
    <h1 class="mb-16">Send certificates</h1>

    <div class="table_wrapper">
      <table class="table_content mb-16">
        <tr class="table_head">
          <td>#</td>
          <td>Email</td>
          <td>Level</td>
          <td>First name</td>
          <td>Last name</td>
          <td>Date from</td>
          <td>Date to</td>
          <td>Year</td>
        </tr>
        <template v-if="processedData.length">
          <tr v-for="(row, index) in processedData" :key="row.index">
            <td>{{ index }}</td>
            <td>{{ row.email }}</td>
            <td>{{ row.level }}</td>
            <td>{{ row.firstName }}</td>
            <td>{{ row.lastName }}</td>
            <td>{{ row.dateFrom }}</td>
            <td>{{ row.dateTo }}</td>
            <td>{{ row.year }}</td>
          </tr>
        </template>
        <tr v-else>
          <td colspan="8">No data found</td>
        </tr>
        <tfoot>
          <tr>
            <td colspan="8">Total: {{ processedData.length }}</td>
          </tr>
        </tfoot>
      </table>
    </div>

    <div v-if="isLoading">
      Loading... Processed {{ successEventCounter }} of {{ tableData.length }}
    </div>

    <template v-else>
      <div v-if="!isParsed">
        <textarea class="text_content" v-model="textContent"></textarea>
        <ui-button @click="parse">Parse to table</ui-button>
        {{ textContent.length }} chars
      </div>

      <div v-else>
        <table class="table_content mb-16">
          <tr class="table_head">
            <td>#</td>
            <td>Email</td>
            <td>Level</td>
            <td>First name</td>
            <td>Last name</td>
            <td>Date from</td>
            <td>Date to</td>
            <td>Year</td>
          </tr>
          <tr v-for="row in tableData" :key="row.index">
            <td>{{ row.index }}</td>
            <td>{{ row.email }}</td>
            <td>{{ row.level }}</td>
            <td>{{ row.firstName }}</td>
            <td>{{ row.lastName }}</td>
            <td>{{ row.dateFrom }}</td>
            <td>{{ row.dateTo }}</td>
            <td>{{ row.year }}</td>
          </tr>
        </table>

        <ui-button @click="isParsed = false" red>Back to textarea</ui-button>
        &nbsp;
        <ui-button @click="send">Send certificates</ui-button>
      </div>
    </template>
  </div>
</template>

<script>
import feathersClient from './../plugins/feathersClient'
import { mapActions } from 'vuex'
import UiButton from '../components/ui/UiButton'

export default {
  name: 'send-certificates',
  components: { UiButton },
  data() {
    return {
      isLoading: false,
      isParsed: false,
      textContent: '',
      tableData: [],
      processedData: {},
      successEventCounter: 0,
    }
  },
  async created() {
    feathersClient.service('certificates').on('created', () => {
      this.successEventCounter++
      if (this.successEventCounter === this.tableData.length) {
        this.isLoading = false
      }
    })
    
    const { data } = await this.findCertificates()
    this.processedData = data
  },
  methods: {
    ...mapActions('certificates', {
      findCertificates: 'find',
    }),
    parse() {
      this.isParsed = true
      console.log('before:', this.textContent)
      const rows = this.textContent.trim().split(/[\r\n]+/)
      console.log('after:', rows)
      this.tableData = rows.map((row, index) => {
        const cols = row.split(/;/).map(col => col.trim())
        return {
          index,
          email: cols[0],
          level: cols[1],
          firstName: cols[2],
          lastName: cols[3],
          dateFrom: cols[4],
          dateTo: cols[5],
          year: cols[6],
        }
      })
      console.log('after2:', this.tableData)
    },
    async send() {
      this.isLoading = true
      this.$notify.success('Certificates processing started!')
      try {
        await this.$store.dispatch('certificates/create', [this.tableData])
      } catch (error) {
        if (error.code !== 408) {
          this.$notify.parseFeathersErrors(error, { duration: 0 })
        }
      }

      this.isLoading = false
    },
  },
}
</script>

<style scoped lang="scss">
.send_certificates {
  padding: 32px;
  width: 100%;
  background: #ffffff;

  .table_wrapper {
    max-height: 345px;
    overflow: scroll;
  }

  .text_content {
    margin-top: 2rem;
    width: 100%;
    height: 300px;
    border: 1px solid #333333;
    margin-bottom: 16px;
  }

  .table_content {
    width: 100%;

    td {
      border: 1px solid #333333;
      padding: 4px;
    }

    .table_head td {
      font-weight: bold;
    }
  }
}
</style>
